



























































































import Vue from 'vue';
import store from '@/store';
import Theme from '@/types/Theme';
import { threadTitleFor } from '@/concerns/threadUtils';
import { RawLocation } from 'vue-router';

export default Vue.extend({
  name: 'NavBar',

  props: {
    shrink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchString: "",
      navCollapseVisible: false,
    };
  },

  computed: {
    username(): string {
      return store.state.user.attributes.username;
    },

    signedIn(): boolean {
      return store.getters.signedIn;
    },

    theme(): Theme {
      return store.state.theme;
    },

    defaultTitle(): string {
      return 'turtello';
    },

    title(): string {
      switch (this.$route.name) {
        case 'ThreadShow': return threadTitleFor(store.state.thread);
        case 'ThreadCompose': return 'New message';
        default: return this.defaultTitle;
      }
    },

    titleRoute(): RawLocation {
      switch (this.$route.name) {
        case 'ThreadShow': return { name: 'ThreadIndex' };
        case 'ThreadCompose': return { name: 'ThreadShow' };
        default: return '/';
      }
    },

    showBackButton(): boolean {
      return this.title !== this.defaultTitle;
    },
  },

  methods: {
    onSearch(_event: Event): void {
      // this.$emit('search', this.searchString);
      this.$emit('not-implemented');
    },

    setTheme(theme: Theme): void {
      store.dispatch('setTheme', { theme });
    },

    signOut(): Promise<void> {
      return store.dispatch('signOut').then(() => {
        if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' });
      });
    },

    // onBlurToggle(): void {
    //   if (this.navCollapseVisible) {
    //     this.$root.$emit('bv::toggle::collapse', 'nav-collapse'); // eslint-disable-line vue/custom-event-name-casing
    //   }
    // },
  },
});
